import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    'ed11ec3d-ab7b-4a07-aee6-29e58a0fa885': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
    '3860f9b6-39c9-4f21-a4d4-c3c88e78e001': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
    '9958cc57-9ea5-4b2c-9abe-32927732d6b3': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1 a 2',
        outOfStock: '5 a 7',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '5 a 7',
  },
})
